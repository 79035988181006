.renovation .modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(203, 209, 255, 0.4);
  z-index: 1;
  width: 90%;
  height: 100%;
  border: 2px dashed #001BFF;
  display: flex;
  justify-content: flex-end;
}
.renovation .modal div {
  align-self: flex-end;
}
.renovation .modal div img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
